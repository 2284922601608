.proposal-body {
  color: white;
  letter-spacing: -0.05rem;
  background: #0c0c14;
}
.proposal-h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.proposal-p {
  font-size: 1.25rem;
  font-weight: 100;
}
@media (min-width: 768px) {
  .proposal-h1 {
      font-size: 3.5rem;
      margin-bottom: 2rem;
  }
  .proposal-p {
      font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .proposal-h1 {
      font-size: 4rem;
  }
  .proposal-p {
      font-size: 2rem;
  }
}
.proposal-image-cover {
  height: 100vh;
  object-fit: cover;
  width: 100%;
}
.proposal-custom-max-width {
  max-width: 700px;
}
.proposal-btn-custom {
  background-color: #796ae7;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  max-width: 300px;
}