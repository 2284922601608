.pagination-container {
    min-width: 7rem;
}

@media only screen and (max-width: 770px) {
    .pagination-container{
        min-width: 100%;
        position: -webkit-sticky; /* Safari & IE */
        position: sticky;
        top: 0;
    }

    .filter-container{
        min-width: 100%;
    }
}